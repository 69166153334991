import { createAction, props } from '@ngrx/store';
import { AdminDashboardReducerInitialState } from '../reducers/admin-dashboard.reducer';
import { QueryClient } from '@tanstack/angular-query-experimental';

const prefix = '[Admin-dashboard]';

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<AdminDashboardReducerInitialState>>()
);

export const setStatAction = createAction(`${prefix} set stat`, props<any>());

export const setQueryAction = createAction(
  `${prefix} set query`,
  props<{
    minDate: string;
    maxDate: string;
    esId?: string;
    pgId?: string;
    pcId?: string;
    tpcId?: string;
    mdaId?: string;
    toId?: string;
    engineCodeId?: string;
    // successAction?: (queryClient: QueryClient) => any;
  }>()
);

export const resetQueryAction = createAction(
  `${prefix} reset query`
  // props<{ successAction?: (queryClient: QueryClient) => any }>()
);
